<script>
import TransitionToggle from "../transitions/toggle.vue";
import SimpleTable from "./simple-table.vue";
import PriceForm from "../forms/price-form.vue";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";

export default {
    name: "price-item",

    components: {PriceForm, SimpleTable, TransitionToggle},

    props: {
        itemId: {
            type: Object,
            required: true
        },

        store: {
            type: String,
            default: 'product'
        }
    },

    computed: {
        ...mapGetters({
            currencies: 'currency/all',
            vats: 'vat/all',
            prices: 'price/all'
        }),

        fields: function () {
            return [
                {
                    key: "is_active",
                    label: this.$t('invoices::prices.columns.is_active').ucFirst(),
                },
                {
                    key: "value",
                    label: this.$t('invoices::prices.columns.value').ucFirst(),
                    formatter: (value, index, item) => {
                        const currency = this.currencies.find(cur => cur.id == item.currency_id);

                        if (currency?.symbol) {
                            return value + ' ' + currency?.symbol
                        }

                        return value
                    }
                },
                {
                    key: "currency_id",
                    label: this.$t('invoices::prices.columns.currency_id').ucFirst(),
                    formatter: (value) => {
                        const currency = this.currencies.find(cur => cur.id == value);

                        if (currency) {
                            return currency.translation_key ? this.$t(currency.translation_key) : currency.name
                        }

                        return value
                    }
                },
                {
                    key: "vat_id",
                    label: this.$t('invoices::prices.columns.vat_id').ucFirst(),
                    formatter: (value) => {
                        const vat = this.vats.find(cur => cur.id == value);

                        if (vat) {
                            return vat.translation_key ? this.$t(vat.translation_key) : vat.name
                        }

                        return value
                    }
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst()
                }
            ];
        },

        billableType: function () {
            switch (this.store) {
                case 'product':
                    return 'Appon\\Projects\\Models\\Product'
                case 'service':
                    return 'Appon\\Projects\\Models\\Service'
            }

            return ''
        },
    },

    data: function () {
        return {
            showPriceForm: false,
            tableUpdateKey: 1
        }
    },

    methods: {
        addPrice: function () {
            this.$store.dispatch('price/clearItem')
            this.showPriceForm = false
        },

        closeForm: function () {
            this.$store.dispatch('price/clearErrors')
            this.showPriceForm = !this.showPriceForm
        },

        removePrice: function (price) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('price/destroy', price.id)
                }
            });
        },

        activatePrice: function (id) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.confirm_title'),
                text: this.$t('base.confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.activate').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('price/update', {id: id, is_active: true, _method: 'PUT'}).then(price => {
                        this.$store.dispatch('price/orderByIsActive', price)
                        this.$store.dispatch('price/clearItem')
                    })
                }
            });

        },

        tableItemClosed: function (callback) {
            this.$store.dispatch('price/clearItem')
            callback()
        },
    },

    created() {
        this.$store.dispatch('price/all', {billable_type: this.billableType, billable_id: this.itemId})
        this.$store.dispatch('currency/all')
        this.$store.dispatch('vat/all')
    },

    unmounted() {
        this.$store.dispatch('price/clearAll')
    }
}
</script>

<template>
    <div class="row">
        <div class="col-12 mb-3">
            <simple-table v-if="prices?.length" :items="prices" :fields="fields" :key="tableUpdateKey">
                <template #is_active="{value}">
                    <i class="fas" :class="{'fa-check text-success': value, 'fa-times text-danger': !value}"></i>
                </template>
                <template #actions="{edit, item}">
                    <span class="text-warning cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="edit(item)">
                        <i class="fas fa-edit"></i>
                    </span>
                    <span class="text-danger cursor-pointer p-2" :title="$t('base.delete').ucFirst()" @click="removePrice(item)">
                        <i class="fas fa-trash-alt"></i>
                    </span>
                    <span v-if="!item.is_active" class="text-primary cursor-pointer p-2" :title="$t('base.activate').ucFirst()" @click="activatePrice(item.id)">
                        <i class="fas fa-check"></i>
                    </span>
                </template>
                <template #default="{close, item}">
                    <price-form class="border rounded p-3" :price="item" :billable-id="item.id" :billable-type="billableType" @saved="tableItemClosed(close)" @cancel="close"></price-form>
                </template>
            </simple-table>
        </div>
        <transition-toggle>
            <price-form class="border rounded p-3" v-if="showPriceForm" :billable-id="itemId" :billable-type="billableType" @saved="addPrice" @cancel="closeForm"></price-form>
        </transition-toggle>
        <transition-toggle>
            <div class="col-12 mb-2 text-center" v-if="!showPriceForm">
                <b-button variant="success" @click="showPriceForm = !showPriceForm">{{ $t('base.add_new_item', {item: $tc('invoices::prices.price', 1)}).ucFirst() }}</b-button>
            </div>
        </transition-toggle>

    </div>
</template>

<style scoped>

</style>

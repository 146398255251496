<script>
import {mapGetters} from "vuex";

export default {
    name: "price-form",

    props: {
        billable: {
            type: Object
        },

        billableType: {
            type: String,
            required: true
        },

        billableId: {
            type: [String, Number],
            required: true
        },

        price: {
            type: Object
        }
    },

    computed: {
        ...mapGetters({
            loading: 'price/loading',
            states: 'price/states',
            errors: 'price/errors',
            item: 'price/item',
            currencies: 'currency/all',
            vats: 'vat/all'
        }),

        defaultCurrency: function () {
            return this.currencies[0]?.id
        },

        defaultVat: function () {
            return this.vats[0]?.id
        },
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = this.model

            return this.$store.dispatch(`price/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved', saved)
            })
        },

        setModel: function (model = {}) {
            const base = {
                billable_type: this.billableType,
                billable_id: this.billableId
            }

            if (!model.vat_id && this.defaultVat) {
                base.vat_id = this.defaultVat
            }

            if (!model.currency_id && this.defaultCurrency) {
                base.currency_id = this.defaultCurrency
            }

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)));
        },

        cancel: function () {
            this.$emit('cancel')
        },
    },

    watch: {
        item: {
            immediate: true,
            deep: true,
            handler: function(value) {
                if (!this.price) {
                    this.setModel(value);
                }
            },
        },

        price: {
            immediate: true,
            deep: true,
            handler: function(value) {
                if (value) {
                    this.setModel(value);
                }
            },
        }
    },

    created() {
        this.$store.dispatch('currency/all')
        this.$store.dispatch('vat/all')
    }

}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row">
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.currency_id === false}"
                        class="form-label"
                        for="currency-input"
                    >{{ $tc('invoices::currencies.currency', 1).ucFirst() }}</label
                    >
                    <select
                        id="currency-input"
                        v-model="model.currency_id"
                        :class="{'border-danger': states.currency_id === false}"
                        class="form-select"
                        name="currency"
                    >
                        <option v-if="false" :value="model.currency_id === undefined ? undefined : null">
                            {{ $t('base.please_select').ucFirst() }}
                        </option>
                        <option v-for="(currency, index) in currencies" :key="index" :value="currency.id">
                            {{ currency.translation_key ? $t(currency.translation_key).ucFirst() : currency.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.currency_id === false}" class="invalid-feedback">{{ errors.currency_id }}</div>
                </div>

                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.vat_id === false}"
                        class="form-label"
                        for="vat-input"
                    >{{ $t('invoices::prices.columns.vat_id').ucFirst() }}</label
                    >
                    <select
                        id="vat-input"
                        v-model="model.vat_id"
                        :class="{'border-danger': states.vat_id === false}"
                        class="form-select"
                        name="vat"
                    >
                        <option v-if="false" :value="model.vat_id === undefined ? undefined : null">
                            {{ $t('base.please_select').ucFirst() }}
                        </option>
                        <option v-for="(vat, index) in vats" :key="index" :value="vat.id">
                            {{ vat.translation_key ? $t(vat.translation_key).ucFirst() : vat.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.vat_id === false}" class="invalid-feedback">{{ errors.vat_id }}</div>
                </div>

                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.value === false}"
                        class="form-label"
                        for="value"
                    >{{ $t('invoices::prices.columns.value').ucFirst() }}</label
                    >
                    <input
                        id="value"
                        v-model="model.value"
                        :class="{'border-danger': states.value === false}"
                        :placeholder="$t('invoices::prices.placeholders.value').ucFirst()"
                        class="form-control"
                        type="number"
                        step="0.01"
                    />
                    <div :class="{'d-block': states.value === false}" class="invalid-feedback">
                        {{ errors.value }}
                    </div>
                </div>

                <div class="col-lg-2">
                    <label
                        :class="{'text-danger': states.is_active === false}"
                        class="form-label"
                        for="is-active-input"
                    >{{ $t('invoices::prices.columns.is_active').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="is-active-input"
                            v-model="model.is_active"
                            :class="{'border-danger': states.is_active === false}"
                            class="form-check-input"
                            name="is_active"
                            type="checkbox"
                        />
                    </div>
                    <div :class="{'d-block': states.is_active === false}" class="invalid-feedback">
                        {{ errors.is_active }}
                    </div>
                </div>

                <div class="col-lg-3 pt-1 mb-2">
                    <button class="btn btn-primary mt-4" type="submit" @click.prevent="save">
                        {{ $t('base.save').ucFirst() }}
                    </button>
                    <button class="btn btn-secondary mt-4 ms-2" type="submit" @click.prevent="cancel">
                        {{ $t('base.cancel').ucFirst() }}
                    </button>
                </div>

            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
